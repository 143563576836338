import React, { Component } from 'react';
import './Global/css/Gpages.css';
class PinturasyAcabados extends Component {
  
  render() {

    //,'','','','','', 'Galeria', 'Contacto']}; 
    return (
      <div className="App-Content">
        <div className={"fullSize  blueSection bannerSection"} id="Home1">
        <div id="BannerInfo">
          <img src={require("./Global/images/Icons/PinturaAcabados.png")} />
          </div>
          <div id="Triangle">

          </div>
        </div>
        <div  id="InfoSection">
          <ul className="blueSection">
          <li><p>Estuco tradicional preparado con caolín, yeso y cemento el cual facilita su implementación, tiempo de entrega y calidad.</p></li>

          <li><p>Estuco plástico listo para aplicar, pero dependiendo de la marca de su preferencia.</p></li>

          <li><p>Pinturas en vinilos usados para muros, techos y exteriores. Nuestra aplicación diferencial es debido a que nuestra técnica le asegura mucho más tiempo de vida en el color y una superficie con un excelente acabado liso.</p></li>

          <li><p>Pinturas en esmaltes son usadas para puertas, ventanas, barandas, pasamanos, pérgolas (Estructuras metálicas).</p></li>

          <li><p>Lacas usadas para cualquier tipo de madera.</p></li>

          <li><p>Barnices, el cual es utilizado como sellante.</p></li>

          <li><p>Pintura de fachadas. Corazas, impermeabilizantes y aplicación de graniacryl y graniplas.</p></li>

          </ul>

        </div>
        
      </div>
      
    );
  }
}


export default PinturasyAcabados;
