import React, { Component } from "react";
import "./css/Footer.css";
import { Link } from "react-router-dom";
class Footer extends Component {
  changeMenuState(index, e) {
    window.scrollTo(0, 0);
  }

  render() {
    const titles = [
      "Obra civil",
      "Pintura y acabados",
      "Cableado estructurado",
      "Sistemas de seguridad eléctronica",
      "Redes eléctricas y electrónica",
      "Sistema liviano"
    ];
    return (
      <footer className="App-footer">
        <div id="FooterUp">
          <div className="footerSection">
            <div id="ContactInfo" className="footerBlock">
              <Link
                onClick={this.changeMenuState.bind(this, 1)}
                to={"/Contacto"}
              >
                <ul>
                  <li className="colum-1-6">
                    <img src={require("./images/Icons/Contacto.png")} />
                  </li>
                  <li className="colum-2-3 textSection">
                    <h2>Diana Urrego</h2>
                    <p>Bogotá, Colombia</p>
                  </li>
                  <li className="colum-1-6">
                    <img src={require("./images/Icons/Contacto2.png")} />
                  </li>
                </ul>
              </Link>
            </div>
            <div id="MailInfo" className="footerBlock">
              <Link
                onClick={this.changeMenuState.bind(this, 1)}
                to={"/Contacto"}
              >
                <ul>
                  <li className="colum-3-4 textSection">
                    <h2>Escríbenos</h2>
                    <p>diana.u@dpusoluciones.com</p>
                  </li>
                  <li className="colum-1-4">
                    <img src={require("./images/Icons/Correo.png")} />
                  </li>
                </ul>
              </Link>
            </div>
          </div>
          <div className="footerSection">
            <div id="GNavigation">
              <ul>
                <li>Inicio</li>
                <li>
                  <Link onClick={this.changeMenuState.bind(this, 1)} to={"/"}>
                    Servicios
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={this.changeMenuState.bind(this, 1)}
                    to={"/Galeria"}
                  >
                    Galeria
                  </Link>
                </li>
                <li className="MobileMenu">
                  <Link
                    onClick={this.changeMenuState.bind(this, 1)}
                    to={"/Contacto"}
                  >
                    Contacto
                  </Link>
                </li>
              </ul>
            </div>
            <div id="Services">
              <ul>
                <li>Servicios</li>
                <li>
                  <Link
                    onClick={this.changeMenuState.bind(this, 1)}
                    to={"/" + titles[0]}
                  >
                    {titles[0]}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={this.changeMenuState.bind(this, 1)}
                    to={"/" + titles[1]}
                  >
                    {titles[1]}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={this.changeMenuState.bind(this, 1)}
                    to={"/" + titles[5]}
                  >
                    {titles[5]}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="FooterDown">
          <p>Atomic Studio 2018. Todos los derechos reservados</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
