import React, { Component } from 'react';
import './Global/css/Gpages.css';
class CableadoEstructurado extends Component {
  
  render() {

    //,'','','','','', 'Galeria', 'Contacto']}; 
    return (
      <div className="App-Content">
        <div className={"fullSize  yellowSection bannerSection"} id="Home2">
        <div id="BannerInfo">
          <img src={require("./Global/images/Icons/CableadoEstructurado.png")} />
          </div>
          <div id="Triangle">

          </div>
        </div>
        <div  id="InfoSection">
          <ul className="yellowSection">
          <li><p>Diseño, montaje y mantenimiento de cableado estructurado, con solución para transmisión de datos, voz o video.</p></li>

          <li><p>Instalación de cableado y fibra óptica.</p></li>

          <li><p>Soluciones inalámbricas de transferencia de datos.</p></li>

          <li><p>Mantenimiento correctivo y preventivo de redes de datos y voz.</p></li>

          <li><p>Instalación de cableado y de plantas telefónicas.</p></li>

          <li><p>Identificación de regletas.</p></li>
          
          <li><p>Diseño, ampliación y actualización de redes existentes.</p></li>
          <li><p>Interventoría en la ejecución de estos proyectos.</p> </li>

          <li><p>Marcación y certificación de puntos.</p></li>
          <li><p>Reubicación de plantas telefónicas.</p></li>
          <li><p>Reubicación de puestos de trabajo.</p></li>

          </ul>

        </div>
        
      </div>
      
    );
  }
}


export default CableadoEstructurado;
