import React, { Component } from "react";
import "./Global/css/App.css";
import Header from "./Global/Header";
import Footer from "./Global/Footer";

import { BrowserRouter, Route } from "react-router-dom";
// Pages
import Home from "./Home";
import ObraCivil from "./Obracivil";
import PinturasyAcabados from "./PinturasYAcabados";
import CableadoEstructurado from "./CableadoEstructurado";
import SistemaSeguridad from "./SistemaSeguridad";
import Redes from "./Redes";
import SistemaLiviano from "./SistemaLiviano";
import Contact from "./Contacto";
import Galery from "./Galery";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="app">
          <Header />
          <Route path="/" component={Home} exact />
          <Route path="/Obra civil" component={ObraCivil} />
          <Route path="/Pintura y acabados" component={PinturasyAcabados} />
          <Route
            path="/Cableado estructurado"
            component={CableadoEstructurado}
          />
          <Route
            path="/Sistemas de seguridad eléctronica"
            component={SistemaSeguridad}
          />
          <Route path="/Redes eléctricas y electrónica" component={Redes} />
          <Route path="/Sistema liviano" component={SistemaLiviano} />
          <Route path="/Contacto" component={Contact} />
          <Route path="/Galeria" component={Galery} />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
