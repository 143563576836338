import React, { Component } from "react";
import "./css/Header.css";
import logo from "./images/Icons/DPU_GrisNegro.png";
import { Link } from "react-router-dom";
class Header extends Component {
  /*constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }*/
  state = {
    count: 0,
    classRef: "blueSection",
    colorRef: "Azul"
  };
  handleClick(index, e) {
    let clicked = this.state.count;
    this.setState({ clicked: clicked });
    this.state.count = this.state.count === 0 ? 1 : 0;
    if (index >= 0) this.selectNumber(index);
  }

  getMenuClass() {
    let menuClass = "";
    menuClass += this.state.count === 0 ? "desactiveMenu" : "activeMenu";

    return menuClass;
  }
  changeMenuColor() {
    this.state.colorRef =
      this.state.classRef == "blueSection" ? "Azul" : "Amarillo";
  }
  selectNumber(color) {
    this.state.classRef = color;
    this.changeMenuColor();
  }

  changeMenuState(index, classColor, e) {
    let clicked = this.state.count;
    this.setState({ clicked: clicked });
    this.state.count = 0;
    window.scrollTo(0, 0);
    if (index >= 0) this.selectNumber(classColor);
  }

  render() {
    const titles = [
      {
        name: "Obra civil",
        typeColor: "yellowSection",
        imageSrc: "Icon_ObraCivil.png"
      },
      {
        name: "Pintura y acabados",
        typeColor: "blueSection",
        imageSrc: "Icon_PinturaYAcabados.png"
      },
      /*{name:'Cableado estructurado', typeColor:"yellowSection", imageSrc: "Icon_CableadoEstructurado.png"},
    {name:'Sistemas de seguridad eléctronica', typeColor:"blueSection", imageSrc: "Icon_SistemaDeSeguridad.png"},
    {name:'Redes eléctricas y electrónica', typeColor:"yellowSection", imageSrc: "Icon_RedesElectricas.png"},*/
      {
        name: "Sistema liviano",
        typeColor: "blueSection",
        imageSrc: "Sistema liviano.png"
      },
      {
        name: "Galeria",
        typeColor: "yellowSection",
        imageSrc: "Sistema liviano.png"
      },
      {
        name: "Contacto",
        typeColor: "blueSection",
        imageSrc: "Sistema liviano.png"
      }
    ];
    const imagesSrc = "./images/Icons/";
    var imageColor = "Azul";
    //function
    return (
      <header className={"App-header "}>
        <div id="Logo">
          <Link
            onClick={this.changeMenuState.bind(this, 0, "blueSection")}
            to={"/"}
          >
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
        </div>
        <nav className={this.state.classRef + " "}>
          <ul id="MobileMenu" className="MobileMenu">
            <a>
              <li onClick={this.handleClick.bind(this, -1)}>
                <img
                  alt="MenuIcon"
                  src={require(imagesSrc + "Btn_Menu_Azul.png")}
                />
              </li>
            </a>
            <li>
              {" "}
              <Link
                onClick={this.changeMenuState.bind(this, 8, "yellowSection")}
                to={"/Contacto"}
              >
                {" "}
                <img
                  alt="contactIcon"
                  src={require(imagesSrc +
                    "Btn_ContactoBarra_Amarillo" +
                    ".png")}
                />
              </Link>
            </li>
          </ul>
          <ul id="NavMenu" className={this.getMenuClass()}>
            {titles.map((titulos, index) => {
              return (
                <li key={index + titulos.name}>
                  <Link
                    className={
                      titulos.name == "Contacto"
                        ? "Contacto_" + this.state.colorRef
                        : ""
                    }
                    onClick={this.changeMenuState.bind(
                      this,
                      index + 1,
                      titulos.typeColor
                    )}
                    key={index}
                    to={"/" + titulos.name}
                  >
                    {" "}
                    {titulos.name}
                  </Link>
                </li>
              );
            })}
            <li onClick={this.handleClick.bind(this, -1)}>
              <a className="MobileMenu">
                <img
                  alt="MenuIcon"
                  src={require(imagesSrc +
                    "Btn_Atras_" +
                    this.state.colorRef +
                    ".png")}
                />
              </a>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Header;
