import React, { Component } from 'react';


const urlIcon="./Global/images/Icons";
const titles= [{name:'Obra civil', typeColor:"yellowSection", imageSrc: "Icon_ObraCivil.png"},
{name:'Pintura y acabados', typeColor:"blueSection", imageSrc: "Icon_PinturaYAcabados.png"},
{name:'Cableado estructurado', typeColor:"yellowSection", imageSrc: "Icon_CableadoEstructurado.png"},
{name:'Sistemas de seguridad eléctronica', typeColor:"blueSection", imageSrc: "Icon_SistemaDeSeguridad.png"},
{name:'Redes eléctricas y electrónica', typeColor:"yellowSection", imageSrc: "Icon_RedesElectricas.png"},
{name:'Sistema liviano', typeColor:"blueSection", imageSrc: "Sistema liviano.png"}];


class Variables extends React.Component {
constructor(){
 
    super();
 
    // Creating Global Variable.
    global.SampleVar = 'This is Global Variable.';
  }
  render() {
      return("");
  } 
}