import React, { Component } from "react";
import "./Global/css/Contacto.css";
class Contacto extends Component {
  render() {
    return (
      <div className="App-Contacto">
        <div id="ContactSection">
          <div className="contactSection" id="InfoContact">
            <div id="ContactImage">
              <div id="ImageContact">
                <img src={require("./Global/images/Contact/IMG_0297_2.png")} />
              </div>
            </div>
            <div id="ContactInfo">
              <ul>
                <li>Diana Urrego</li>
                <li>Cel: 3213093439</li>
                <li>Tel: 3045074</li>
              </ul>
            </div>
          </div>
          <div className="contactSection" id="FormContact">
            <form id="formulario" action="php/action.php" method="post">
              <label className="formInputs">
                Para: diana.u@dpusoluciones.com
              </label>
              <input
                className="formInputs"
                placeholder="Tu correo"
                name="Correo"
                type="text"
              />
              <input
                className="formInputs"
                placeholder="Asunto"
                name="Asunto"
                type="text"
              />
              <textarea
                className="formInputs"
                placeholder="Descripcion"
                name="message"
                rows="10"
                cols="30"
              />
              <button type="submit">Enviar</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Contacto;
