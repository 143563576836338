import React, { Component } from 'react';
import './Global/css/Gpages.css';
class SistemaSeguridad extends Component {
  
  render() {
    

    //,'','','','','', 'Galeria', 'Contacto']}; 
    return (
      <div className="App-Content">
        <div className={"fullSize  blueSection bannerSection"} id="Home3">
        <div id="BannerInfo">
          <img src={require("./Global/images/Icons/SistemaDeSeguridad.png")} />
          </div>
          <div id="Triangle">

          </div>
        </div>
        <div  id="InfoSection">
          <ul className="blueSection">
          <li><p>Soluciones de identificación, control de accesos, sistemas cerrados de televisión, biometría.</p></li>

          <li><p>Diseño, montajes y puesta en funcionamiento de equipos de seguridad.</p></li>

          <li><p>Electrónica Domos, cámaras y minicámaras CCTV (circuito cerrado de televisión).</p></li>

          <li><p>Control de acceso de incendios.</p></li>

          <li><p>Instalación de equipos de Alarmas y monitoreo.</p></li>

          <li><p>Instalación de Video porteros.</p></li>

          </ul>

        </div>
        
      </div>
      
    );
  }
}


export default SistemaSeguridad;
