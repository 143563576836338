//import React, { Component } from 'react';
import "./Global/css/Gpages.css";
import React from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-images";

const photos = [
  {
    src: require("./Global/images/BackGround/Foto_CableadoEstructurado_1366x768.png"),
    width: 16,
    height: 9
  },
  {
    src: require("./Global/images/BackGround/Foto_Galeria_1366x768.png"),
    width: 16,
    height: 9
  },
  {
    src: require("./Global/images/BackGround/Foto_Home_1366x768.png"),
    width: 16,
    height: 9
  },
  {
    src: require("./Global/images/BackGround/Foto_ObraCivil_1366x768.png"),
    width: 16,
    height: 9
  },
  {
    src: require("./Global/images/BackGround/Foto_PinturaAcabados_1366x768.png"),
    width: 16,
    height: 9
  },
  {
    src: require("./Global/images/BackGround/Foto_RedesELectricas_1366x768.png"),
    width: 16,
    height: 9
  },
  {
    src: require("./Global/images/BackGround/Foto_SistemaLiviano_1366x768.png"),
    width: 16,
    height: 9
  },
  {
    src: require("./Global/images/BackGround/Foto_SistemaSeguridad_1366x768.png"),
    width: 16,
    height: 9
  },
  {
    src: require("./Global/images/BackGround/FondoWeb1.jpg"),
    width: 16,
    height: 9
  }
];

class Galery extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  render() {
    return (
      <div className="App-Content">
        <div className={"fullSize  blueSection bannerSection"} id="Gallery">
          <div id="BannerInfo">
            <img
              src={require("./Global/images/Icons/Galeria.png")}
              alt="FondoWeb"
            />
          </div>
          <div id="Triangle" />
        </div>
        <div>
          <Gallery photos={photos} onClick={this.openLightbox} />
          <Lightbox
            images={photos}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
        </div>
      </div>
    );
  }
  /*
  render() {
    const urlIcon="./Global/images/Icons";
    const titles= [{name:'Obra civil', typeColor:"yellowSection", imageSrc: "Icon_ObraCivil.png"}];
    

    //,'','','','','', 'Galeria', 'Contacto']}; 
    return (
      <div className="App-Content">
        <div className={"fullSize  blueSection bannerSection"} id="Home1">
          <div id="BannerInfo">
            <img src={require("./Global/images/Icons/PinturaAcabados.png")} />
          </div>
          <div id="Triangle">

          </div>
        </div>
        <div  id="InfoSection">
          
        <ul>
          <li>Galeria</li></ul>
        </div>
        
      </div>
      
    );
  }*/
}

export default Galery;
