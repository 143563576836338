import React, { Component } from 'react';
import './Global/css/Gpages.css';
class SistemaLiviano extends Component {
  
  render() {
    

    //,'','','','','', 'Galeria', 'Contacto']}; 
    return (
      <div className="App-Content">
        <div className={"fullSize  blueSection bannerSection"} id="Home5">
        <div id="BannerInfo">
          <img src={require("./Global/images/Icons/SistemaLiviano.png")} />
          </div>
          <div id="Triangle">

          </div>
        </div>
        <div  id="InfoSection">
          <ul className="blueSection">
            <li><p>Cielorraso en drywall.</p></li>     
            <li><p>Cielorraso en fibra mineral.</p></li>
            <li><p>Muros drywall en una o dos caras.</p></li>
            <li><p>Antepechos los cuales son los muros en la parte inferior de una ventana.</p></li>
            <li><p>Cenefas en yeso las cuales van en todo el perimetral de los cielorrasos.</p></li>
            <li><p>Cortineros en drywall usados para instalación de las cortinas.</p></li>
            <li><p>Figuras en drywall y en yeso para cielorrasos y muros.</p></li>
          </ul>

        </div>
        
      </div>
      
    );
  }
}


export default SistemaLiviano;
