import React, { Component } from "react";
import "./Global/css/Home.css";
import { Link } from "react-router-dom";
class Home extends Component {
  changeMenuState(index, e) {
    window.scrollTo(0, 0);
  }
  render() {
    const urlIcon = "./Global/images/Icons";
    const titles = [
      {
        name: "Obra civil",
        typeColor: "yellowSection",
        imageSrc: "Icon_ObraCivil.png"
      },
      {
        name: "Pintura y acabados",
        typeColor: "blueSection",
        imageSrc: "Icon_PinturaYAcabados.png"
      },
      {
        name: "Sistema liviano",
        typeColor: "yellowSection",
        imageSrc: "Sistema liviano.png"
      }
    ];

    //const titles=titles;
    //,'','','','','', 'Galeria', 'Contacto']};
    return (
      <div className="App-home">
        <div className="fullSize blueSection bannerSection" id="Banner">
          <div id="BannerInfo">
            <h1>Calidad, agilidad y efectividad</h1>
            <p> Nos adaptamos a sus necesidades{global.SampleVar}</p>
          </div>
        </div>
        <ul>
          {titles.map((titulos, index) => {
            return (
              <li
                id={"Home" + index}
                className={titulos.typeColor + " fullSize "}
                key={index}
              >
                <div className="bannerSection">
                  <div className="infoServices">
                    <img
                      alt={titulos.imageSrc}
                      src={require(urlIcon + "/" + titulos.imageSrc + "")}
                    />
                    <Link
                      onClick={this.changeMenuState.bind(
                        this,
                        8,
                        "yellowSection"
                      )}
                      to={"/" + titulos.name}
                    >
                      {" "}
                      <div className="infoBtn btnObj">
                        {" "}
                        <h3>Info</h3>
                      </div>
                    </Link>
                    <Link
                      onClick={this.changeMenuState.bind(
                        this,
                        8,
                        "yellowSection"
                      )}
                      to={"/Galeria"}
                    >
                      <div className="galeryBtn btnObj">
                        <h3>Galeria</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Home;
