import React, { Component } from 'react';
import './Global/css/Gpages.css';
class Redes extends Component {
  
  render() {

    //,'','','','','', 'Galeria', 'Contacto']}; 
    return (
      <div className="App-Content">
        <div className={"fullSize  yellowSection bannerSection"} id="Home4">
        <div id="BannerInfo">
          <img src={require("./Global/images/Icons/RedesElectricas.png")} />
          </div>
          <div id="Triangle">

          </div>
        </div>
        <div  id="InfoSection">
          <ul className="yellowSection">
            <li><p>Suministro, montaje y puesta en servicio de sistemas de energía (UPS, plantas eléctricas, energía solar, etc).</p></li>
            <li><p>Diseño y construcción de instalaciones residenciales e industriales.</p></li>
            <li><p>Diseño y construcción de sistemas de puesta a tierra.</p></li>
            <li><p>Organización y montaje de gabinetes.</p></li>
            <li><p>Instalación y puesta en funcionamiento de tableros eléctricos normales y regulados con sus respectivas protecciones.</p></li>
            <li><p>Diseño, construcción y mantenimiento de alumbrados interiores y exteriores.</p></li>
            <li><p>Diseño, construcción y mantenimiento de subestaciones, sistemas de emergencia.</p></li>
            <li><p>Interventoría en proyectos eléctricos de Media y Baja Tensión.</p></li>
            <li><p>Diseño de sistemas eléctricos con suministro de planos, memorias de cálculo y diagramas unifilares.</p></li>

          </ul>

        </div>
        
      </div>
      
    );
  }
}


export default Redes;
