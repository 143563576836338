import React, { Component } from 'react';
import './Global/css/Gpages.css';
class Obracivil extends Component {
  
  render() {

    //,'','','','','', 'Galeria', 'Contacto']}; 
    return (
      <div className="App-Content">
        <div className={"fullSize  yellowSection bannerSection"} id="Home0">
        <div id="BannerInfo">
          <img src={require("./Global/images/Icons/ObraCivil.png")} />
          </div>
          <div id="Triangle">

          </div>
        </div>
        <div  id="InfoSection">
          <ul className="yellowSection">
            <li><p>Construcción De Edificaciones: Obras verticales, viviendas, Locales Comerciales, Bodegas, Hospitales en general.</p></li>
            <li><p>Remodelación Y Conservación De Edificaciones: Diseño, replanteo de obra, demoliciones, estructuras en concreto, mampostería, pañetes, pinturas, estucos y alistados.</p></li>
            <li><p>Mantenimiento correctivo de edificaciones.</p></li>
            <li><p>Impermeabilizaciones y mantenimiento de cubiertas.</p></li>
            <li><p>Planos y Diseños.</p></li>
          </ul>

        </div>
        
      </div>
      
    );
  }
}


export default Obracivil;
